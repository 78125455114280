<template>
  <div style="display: flex; flex-wrap: wrap">
    <template v-for="(item, index) in data.text" :key="index">
      <div
        class="text-box"
        v-if="item.name === 'text'"
        v-html="item.text"
      ></div>
      <img class="text-img" v-else-if="item.name === 'img'" :src="item.src" />
    </template>
  </div>
</template>

<script>
import { defineComponent, watchEffect, reactive, toRefs } from "vue";

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const data = reactive({
      data: {},
    });

    watchEffect(() => {
      data.data = props.data;
    });

    return {
      ...toRefs(data),
    };
  },
});
</script>
<style lang="scss" scoped>
.text-img {
  width: 20px;
  height: 20px;
  display: inline-block;
}

.text-box {
  display: inline-block;
  word-break: break-all;
  height: fit-content;

  a {
    color: #409eff;
    text-decoration: underline;
  }
}
</style>
<style lang="scss">
.text-box {
  a {
    color: #409eff;
    text-decoration: underline;
  }

  p {
    margin: 0px;
  }
}
</style>
