<template>
  <el-button
    type="primary"
    style="width: 100%; height: 50px"
    plain
    @click="openConnectDialog"
    >座席主动联系用户</el-button
  >
  <el-dialog v-model="dialogVisible" title="座席主动联系用户" width="500">
    <el-form :model="connectForm" label-width="120px">
      <el-form-item label="IM 用户ID">
        <el-select
          v-model="connectForm.IMUser"
          placeholder="请选择 IM 用户"
          filterable
          remote
          :remote-method="searchIMUser"
        >
          <el-option
            v-for="item in IMUserList"
            :key="item.UserID"
            :label="item.Nick"
            :value="item.UserID"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="IM 客服虚拟号">
        <el-select
          v-model="connectForm.IMAgent"
          placeholder="请选择 IM 客服虚拟号"
        >
          <el-option
            v-for="item in IMAgentList"
            :key="item.imAgentUserId"
            :label="item.imAgentUserName"
            :value="item.imAgentUserId"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="cancelFrom">取消</el-button>
        <el-button type="primary" @click="submitForm"> 确定 </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { defineComponent, reactive, toRefs, watchEffect, inject } from "vue";

export default defineComponent({
  name: "ConnectIMUser",
  setup() {
    const data = reactive({
      tcccSdk: null,
      dialogVisible: false,
      IMUserList: [],
      IMAgentList: [],
      connectForm: {
        IMUser: "",
        IMAgent: "",
      },
    });
    const tcccsdk = inject("tcccsdk");
    watchEffect(() => {
      data.tcccSdk = tcccsdk.value;
    });
    const openConnectDialog = () => {
      data.dialogVisible = true;
      getIMAgentListForContactUser();
    };
    const getIMAgentListForContactUser = async () => {
      if (data.tcccSdk) {
        const res = await data.tcccSdk.Chat.getIMAgentListForContactUser();
        data.IMAgentList = res.data?.data;
      }
    };
    const searchIMUser = async (userID) => {
      data.IMUserList = [];
      if (data.tcccSdk && userID) {
        const res = await data.tcccSdk.Chat.getIMUserProfile([userID]);
        for (const item of res.data.UserProfileItems) {
          // 首先看有没有查询到
          if (item.ErrorCode === 0) {
            data.IMUserList.push({
              UserID: item.UserID,
              Nick: item.Nick,
            });
          }
        }
      }
    };
    const cancelFrom = () => {
      data.dialogVisible = false;
      data.connectForm = {
        IMUser: "",
        IMAgent: "",
      };
    };
    const submitForm = async () => {
      if (data.tcccSdk) {
        await data.tcccSdk.Chat.startFollowUpConversation({
          channelAgentID: data.connectForm.IMAgent,
          ClientUserID: data.connectForm.IMUser,
        });
        data.dialogVisible = false;
        data.connectForm = {
          IMUser: "",
          IMAgent: "",
        };
      }
    };
    return {
      ...toRefs(data),
      openConnectDialog,
      submitForm,
      searchIMUser,
      cancelFrom,
    };
  },
});
</script>

<style lang="scss" scoped></style>
