<template>
  <div class="customMessage" :data-item="JSON.stringify(payload)">
    <div v-if="payload.src === CUSTOM_MESSAGE_SRC.BRANCH">
      <MessageBranch :payload="payload"></MessageBranch>
    </div>
    <div v-else-if="payload.src === CUSTOM_MESSAGE_SRC.FROM_INPUT">
      <MessageFormSave :payload="payload"></MessageFormSave>
    </div>
    <div v-else-if="payload.src === CUSTOM_MESSAGE_SRC.PRODUCT_CARD">
      <MessageProductCard :payload="payload"></MessageProductCard>
    </div>
    <div v-else-if="payload.src === CUSTOM_MESSAGE_SRC.ORDER_CARD">
      <MessageOrder :payload="payload" :message="data"></MessageOrder>
    </div>
    <div v-else-if="payload.src === CUSTOM_MESSAGE_SRC.RICH_TEXT">
      <MessageImrobotRichText
        :content="payload.content"
      ></MessageImrobotRichText>
    </div>
    <div v-else>[自定义消息]</div>
  </div>
</template>

<script>
import { defineComponent, watchEffect, reactive, toRefs } from "vue";
import { JSONToObject } from "../../../../utils/index";
import { CUSTOM_MESSAGE_SRC, ROBOT_COMMAND } from "../../../../constants/index";
import MessageBranch from "./message-branch.vue";
import MessageFormSave from "./message-formSave.vue";
import MessageProductCard from "./message-productCard.vue";
import MessageImrobotRichText from "./message-imrobot-richText.vue";
import MessageOrder from "./message-order.vue";

export default defineComponent({
  components: {
    MessageBranch,
    MessageFormSave,
    MessageProductCard,
    MessageImrobotRichText,
    MessageOrder,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const data = reactive({
      data: {},
      payload: {},
    });

    watchEffect(() => {
      data.data = props.data;
      data.payload = JSONToObject(data.data?.payload?.data);
    });

    return {
      ...toRefs(data),
      ROBOT_COMMAND,
      CUSTOM_MESSAGE_SRC,
    };
  },
});
</script>

<style lang="scss" scoped>
a {
  color: #679ce1;
}

.customMessage {
  font-size: 14px;

  h1 {
    font-size: 14px;
    color: #000000;
  }

  h1,
  a,
  p {
    font-size: 14px;
  }
}
</style>
