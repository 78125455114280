<template>
  <div class="richTextContainer" v-html="cleanContent"></div>
</template>

<script>
import { defineComponent, watchEffect, reactive, toRefs } from "vue";
import { marked } from "marked";
import * as DOMPurify from "dompurify";

export default defineComponent({
  props: {
    content: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const data = reactive({
      content: {},
      cleanContent: "",
    });

    watchEffect(() => {
      data.content = props.content;
      data.content = marked(data.content);
      data.cleanContent = DOMPurify.sanitize(data.content);
    });

    return {
      ...toRefs(data),
    };
  },
});
</script>
<style lang="scss" scoped></style>
<style lang="scss">
.richTextContainer {
  p {
    img {
      width: 100%;
      height: 100%;
      max-height: 300px;
      object-fit: contain;
    }
  }
}
</style>
