<template>
  <div :class="['userItem', { selected: selected }]" @click="choseSession">
    <div class="avatar-wrapper">
      <img
        class="userItemAvtar"
        src="https://qcloudimg.tencent-cloud.cn/raw/9c6b6806f88ee33b3685f0435fe9a8b3.png"
      />
      <div v-if="userInfo?.unreadCount !== 0" class="dot">
        {{ userInfo?.unreadCount }}
      </div>
    </div>
    <div style="flex: 1; margin-left: 10px">
      <div class="userItemUserName" :style="{ color: selected ? 'white' : '' }">
        {{ userInfo?.nickname }}
      </div>
      <div
        v-if="userInfo?.lastMessage"
        class="userItemLastMessage"
        :style="{ color: selected ? 'white' : '' }"
      >
        {{ formateLastMessage(userInfo?.lastMessage) }}
      </div>
      <div
        v-if="!userInfo?.lastMessage"
        class="userItemLastMessage"
        :style="{ color: selected ? 'white' : '' }"
      ></div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs, watchEffect } from "vue";
import { formateLastMessage } from "../../../../utils/index";
import { useStore } from "vuex";

export default defineComponent({
  name: "UserItem",
  props: {
    userInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const data = reactive({
      userInfo: {},
      selected: false,
    });
    const store = useStore();
    const selectedSession = computed(() => store.state.session.selectedSession);

    watchEffect(() => {
      data.userInfo = props.userInfo;
    });

    watchEffect(() => {
      data.selected =
        selectedSession?.value?.sessionId === data.userInfo.sessionId;
    });

    const choseSession = async () => {
      // 点击当前的会话不需要反应
      if (selectedSession?.value?.sessionId === data.userInfo.sessionId) {
        return;
      }
      store.commit("message/initMessageList", []);
      store.commit("session/setCurrentSession", data.userInfo);
    };

    return {
      ...toRefs(data),
      choseSession,
      formateLastMessage,
    };
  },
});
</script>

<style lang="scss" scoped>
.selected {
  background: linear-gradient(90deg, #006eff 0%, #4696ff 100%);
  border-bottom: 1px solid #f7f7f7;
}

.userItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 0 10px 10px;
  border-radius: 2px;
  width: 220px;

  .avatar-wrapper {
    position: relative;
    display: inline-block;
  }

  .dot {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: red;
    font-size: 12px;
    color: white;
    line-height: 20px;
    text-align: center;
  }

  .userItemAvtar {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 2px;
  }

  .userItemUserName {
    color: rgba(0, 0, 0, 0.87);
    overflow: hidden;
    font-size: 14px;
    font-family: PingFangSC-Medium !important;
    font-weight: 500;
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .userItemLastMessage {
    color: #8a8f97;
    overflow: hidden;
    font-size: 12px;
    margin-top: 3px;
    max-height: 17px;
    min-height: 17px;
    font-family: PingFangSC-Regular !important;
    line-height: 17px;
    height: 17px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .userItemConncetTime {
    color: rgba(0, 0, 0, 0.38);
    font-size: 12px;
    text-align: right;
    font-family: PingFangSC-Regular !important;
    font-weight: 400;
    line-height: 20px;
    height: 40px;
    align-self: flex-end;
    flex-shrink: 0;
    margin-right: 8px;
  }
}
</style>
