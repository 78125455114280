<template>
  <div class="upload-btn icon icon-rate" @click="sendRatingMessage"></div>
</template>

<script>
import { ElMessage } from "element-plus";
import { defineComponent, inject, reactive, toRefs, watchEffect } from "vue";

export default defineComponent({
  name: "RateButton",
  props: {
    activeSession: {
      type: Object,
      default: () => null,
    },
  },
  setup(props) {
    const data = reactive({
      tcccSdk: null,
      activeSession: {},
    });
    const tcccsdk = inject("tcccsdk");
    watchEffect(() => {
      data.activeSession = props.activeSession;
      data.tcccSdk = tcccsdk.value;
    });

    const sendRatingMessage = async () => {
      if (data.tcccSdk && data.activeSession?.sessionId) {
        try {
          await data.tcccSdk.Chat.sendSessionRating({
            sessionId: data.activeSession.sessionId,
          });
        } catch (e) {
          ElMessage.error("请求用户评价失败");
          console.log(e);
        }
      }
    };
    return {
      ...toRefs(data),
      sendRatingMessage,
    };
  },
});
</script>

<style lang="scss" scoped>
.upload-btn {
  position: relative;
}

.icon {
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
}

.icon-rate {
  margin: 12px 10px 0;
  background: url("../../../../assets/icon/rate.svg");
  background-size: contain;
}
</style>
