<template>
  <div v-for="item in endedList" :key="item.sessionId">
    <UserItem :userInfo="item" :type="'ending'"></UserItem>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, watchEffect } from "vue";
import UserItem from "../UserItem/index.vue";

export default defineComponent({
  name: "EndedList",
  components: {
    UserItem,
  },
  props: {
    endedList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const data = reactive({
      endedList: [],
    });
    watchEffect(() => {
      data.endedList = props.endedList;
    });
    return {
      ...toRefs(data),
    };
  },
});
</script>
<style lang="scss" scoped></style>
