<template>
  <el-tabs
    :stretch="true"
    v-model="activeName"
    @tab-change="selectedItem = null"
  >
    <el-tab-pane label="转接座席" name="Seat">
      <div style="width: 100%; text-align: center">
        <div class="TransferItemContainer">
          <TransferItem
            v-for="item in seatList"
            :key="item.userId"
            :showName="item.staffName"
            :type="'seat'"
            :item="item"
            @selectTransferItem="selectTransferItem"
            :selected="item?.userId == selectedItem?.userId"
          >
          </TransferItem>
        </div>
        <el-button
          v-if="selectedItem != null"
          class="transferButton"
          type="primary"
          @click="startTransfer"
          >开始转接</el-button
        >
      </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { defineComponent, inject, reactive, toRefs, watchEffect } from "vue";
import TransferItem from "./TransferItem/index.vue";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import { getAllFreeSeat } from "../../utils/api";

export default defineComponent({
  name: "TransferCompionent",
  components: {
    TransferItem,
  },
  props: {
    activeSession: {
      type: Object,
      default: () => null,
    },
  },
  setup(props, ctx) {
    const data = reactive({
      tcccSdk: null,
      activeName: "Seat",
      seatList: [],
      selectedItem: null,
      activeSession: {},
      loading: false,
    });
    const tcccsdk = inject("tcccsdk");
    const store = useStore();
    watchEffect(async () => {
      data.tcccSdk = tcccsdk.value;
      data.activeSession = props.activeSession;
      if (data.tcccSdk) {
        const initSDKRes = JSON.parse(sessionStorage.getItem('sdkconfig'))
        let getdata = {
          SdkAppId: initSDKRes.sdkAppId,
          StatusList: ["free"],
        }
        const freeData = await getAllFreeSeat(getdata);
        data.seatList = freeData.allFreeSeatRes;
      }
    });
    const selectTransferItem = (option) => {
      data.selectedItem = option.item;
      data.selectedItem.type = option.type;
    };
    const startTransfer = async () => {
      try {
        if (data.loading) {
          return;
        }
        data.loading = true;
        if (
          data.tcccSdk &&
          data?.activeSession?.sessionId &&
          data?.selectedItem?.type
        ) {
          if (data.selectedItem.type == "skillGroup") {
            await data.tcccSdk.Chat.transfer({
              sessionId: data.activeSession.sessionId,
              skillGroupId: data.selectedItem.skillGroupId,
            });
          }
          if (data.selectedItem.type == "seat") {
            await data.tcccSdk.Chat.transfer({
              sessionId: data.activeSession.sessionId,
              userId: data.selectedItem.userId,
            });
          }
        }
        ElMessage.success("转接成功");
        ctx.emit("transferEnd");
        store.commit("session/setCurrentSession", undefined);
        store.commit("session/completeOuterSessionList", data.activeSession);
        data.loading = false;
      } catch (e) {
        console.log(e);
        data.loading = false;
        ElMessage.error(e?.message);
      }
    };
    return {
      ...toRefs(data),
      selectTransferItem,
      startTransfer,
    };
  },
});
</script>

<style lang="scss" scoped>
.TransferItemContainer {
  background-color: #fff;
  border-radius: 0;
  box-shadow: 0 2px 4px 0 rgba(54, 58, 80, 0.32);
  box-sizing: border-box;
  margin: 20px;
  padding: 20px;
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  max-height: 500px;
}

.transferButton {
  justify-self: center;
}
</style>
