// history模式
import {
    createRouter,
    createWebHashHistory,
} from 'vue-router'

import Home from '../pages/Home.vue'
import loginIndex from '../pages/LoginIndex.vue'

const routes = [
// 路由的默认路径
    {
        path:'/',
        redirect:"/loginIndex"
    },
    {
        path: '/home',
        component: Home
    },
    {
        path: '/loginIndex',
        component: loginIndex
    },
]

// 创建路由对象
const router = createRouter({
    history: createWebHashHistory(),
    routes
})


export default router;
