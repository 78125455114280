<!--  右边全部 -->
<template>
  <div  class="rightIndex">
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
    <el-tab-pane label="客户信息" name="info">
   <information></information>
    </el-tab-pane>
    <!-- <el-tab-pane label="快捷回复" name="fast">
      <fastreply></fastreply>
    </el-tab-pane> -->

  </el-tabs>
  </div>
  
</template>
  
<script  setup >
import { ref } from 'vue'
import information from "./information/index.vue"
// import fastreply from "./fastreply/index.vue"
const activeName = ref('info')
const handleClick = (tab, event) => {
  console.log(tab, event)
}
</script>
  
<style lang="scss" scoped>
.rightIndex{
  width: 600px;
}
.demo-tabs{
  padding: 0 40px;
  width: 100%;
  box-sizing: border-box;
}
  .demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
</style>
  