<template>
  <div class="outerContainer">
    <ScrollButton
      :isScrollOverOneScreen="isScrollOverOneScreen"
      @scrollToMessageListBottom="scrollToMessageListBottom"
    >
    </ScrollButton>
    <div
      class="messageListContainer"
      @scroll="messageListScroll"
      ref="scrollContainer"
      id="scrollContainer"
    >
      <div
        v-for="(item, index) in messageList"
        :key="item.ID"
        @click.right.prevent="openActions($event, item)"
        ref="target"
        :id="item.ID"
        data-elementtype="message"
      > 
        <MessageTimestamp
          :currTime="item.time"
          :prevTime="index > 0 ? messageList[index - 1].time : 0"
        />
        <InfoMessage :data="item" v-if="isInfoMessage(item)"></InfoMessage>
        <div v-else-if="!item.isRevoked" style="position: relative">
          <MessageItem :message="item"></MessageItem>
          <MessageActions
            v-if="item?.ID && selectedMessage?.ID === item?.ID"
            :message="selectedMessage"
          ></MessageActions>
        </div>

        <div v-else-if="item.isRevoked" class="revokeMessage">
          {{ item.flow == "in" ? "对方" : "你" }}撤回了一条消息
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onClickOutside } from "@vueuse/core";
import {
  computed,
  defineComponent,
  inject,
  reactive,
  ref,
  toRefs,
  watch,
  watchEffect,
  nextTick,
  onMounted,
  onUnmounted,
} from "vue";
import MessageItem from "./MessageItem/index.vue";
import {
  formateHistoryMessage,
  isMessageVisible,
  isInfoMessage,
} from "../../../utils/index";
import { SESSION_STATUS } from "../../../constants/index";
import debounce from "lodash/debounce";
import MessageActions from "./MessageActions/index.vue";
import { useStore } from "vuex";
import InfoMessage from "./messages/message-info.vue";
import MessageTimestamp from "./messages/message-timestamp.vue";
import throttle from "lodash/throttle";
import ScrollButton from "./ScrollButton/index.vue";

export default defineComponent({
  name: "ChatMessageList",
  components: {
    MessageItem,
    MessageActions,
    InfoMessage,
    MessageTimestamp,
    ScrollButton,
  },
  props: {
    activeSession: {
      type: Object,
      default: () => null,
    },
  },
  setup(props) {
    const data = reactive({
      tcccSdk: null,
      activeSession: {},
      messageList: computed(() => store?.state?.message?.messageList),
      nextReqMessageID: "",
      cursor: "",
      selectedMessage: {},
      isComplate: false,
      isScrollOverOneScreen: false,
    });
    let observer = null;
    const sentReceiptMessageIDSet = new Set();
    const currentLastMessage = ref();
    const beforeHistoryGetScrollHeight = ref(0);
    const store = useStore();
    const target = ref(null);
    const scrollContainer = ref(null);
    const tcccsdk = inject("tcccsdk");

    // 初始化消息列表
    watchEffect(async () => {
      data.activeSession = props.activeSession;
      data.tcccSdk = tcccsdk.value;
      if (data.tcccSdk) {
        if (data.activeSession.status !== SESSION_STATUS.FINISHED) {
          const res = await data.tcccSdk.Chat.getMessageList({
            sessionId: data.activeSession.sessionId,
          });
          store.commit(
            "message/initMessageList",
            res?.data?.messageList.filter((item) => isMessageVisible(item))
          );
          data.isComplate = res.data?.isComplateisComplate;
          data.nextReqMessageID = res.data?.nextReqMessageID;
        }
        if (data.activeSession.status === SESSION_STATUS.FINISHED) {
          const res = await data.tcccSdk.Chat.getHistoryMessageList({
            sessionId: data.activeSession.sessionId,
            size: 20,
            direct: "down",
          });
          store.commit(
            "message/initMessageList",
            res?.data?.details
              .filter((item) => item.type != "separator")
              .map((item) => formateHistoryMessage(item,store?.state?.userInfo?.userInfo?.userId))
              .filter((item) => isMessageVisible(item))
          );
          console
          if (res?.data?.details.length > 0) {
            data.cursor =
              res?.data?.details[res?.data?.details.length - 1].cursor;
          }
        }
      }
    });

    // 如果不是客户或者其他客服的消息，消息需要靠右
    const isReverseMessage = (message) => {
      if (message.flow == "out") {
        return true;
      }
      if (message.flow == "in" && message.from == "@TIM#SYSTEM") {
        return true;
      }
      if (message.flow == "in" && message.from == "administrator") {
        return true;
      }
      return false;
    };

    // 列表滚动到顶部和底部拉消息
    const messageListScroll = debounce(async (event) => {
      console.log("messageListScroll");
      try {
        const { scrollTop, scrollHeight, clientHeight } = event.target;
        if (scrollTop === 0) {
          if (
            data.activeSession.status === SESSION_STATUS.IN_PROGRESS &&
            !data.isComplate &&
            data.nextReqMessageID
          ) {
            const res = await data.tcccSdk.Chat.getMessageList({
              sessionId: data.activeSession.sessionId,
              nextReqMessageID: data.nextReqMessageID,
            });
            beforeHistoryGetScrollHeight.value =
              scrollContainer?.value?.scrollHeight;
            store.commit("message/updateMessageList", {
              messages: res?.data?.messageList.filter((item) =>
                isMessageVisible(item)
              ),
              type: "up",
            });
            data.isComplate = res.data?.isComplateisComplate;
            data.nextReqMessageID = res.data?.nextReqMessageID;
          }
        }
        if (scrollHeight - scrollTop - clientHeight < 5) {
          if (
            data.activeSession.status === SESSION_STATUS.FINISHED &&
            data.cursor
          ) {
            const res = await data.tcccSdk.Chat.getHistoryMessageList({
              size: 20,
              direct: "down",
              cursor: data.cursor,
            });
            console.log("state?.userInfo?.us",store?.state?.userInfo?.userInfo?.userId)
            store.commit("message/updateMessageList", {
              messages: res?.data?.details
                .filter((item) => item.type != "separator")
                .map((item) => formateHistoryMessage(item,store?.state?.userInfo?.userInfo?.userId))
                .filter((item) => isMessageVisible(item)),
              type: "down",
            });
            if (res?.data?.details.length > 0) {
              data.cursor =
                res?.data?.details[res?.data?.details.length - 1].cursor;
            }
          }
        }
      } catch (e) {
        console.log(e, "sendMessageReadReceipt");
      }
    }, 1000);

    // 点击其他区域要关闭消息操作弹框
    onClickOutside(target, () => {
      data.selectedMessage = {};
    });

    // 右键打开消息操作弹框
    const openActions = (event, item) => {
      if (data.activeSession.status != SESSION_STATUS.IN_PROGRESS) {
        return;
      }
      data.selectedMessage = item;
    };

    // 每条消息单独设置已读回执
    async function bindIntersectionObserver() {
      if (
        !data.messageList ||
        !scrollContainer.value ||
        data.messageList.length === 0
      ) {
        return;
      }
      const mappingFromIDToMessage = {};
      observer?.disconnect();
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach(async (entry) => {
            const { isIntersecting, target } = entry;
            if (isIntersecting) {
              const { msgDom, msgModel } = mappingFromIDToMessage[target.id];
              if (
                msgModel &&
                !msgModel.readReceiptInfo?.isPeerRead &&
                !sentReceiptMessageIDSet.has(msgModel.ID)
              ) {
                await data.tcccSdk.Chat.sendMessageReadReceiptV2({
                  messages: [msgModel],
                });
                sentReceiptMessageIDSet.add(msgModel.ID);
                observer?.unobserve(msgDom);
              }
            }
          });
        },
        {
          root: scrollContainer.value,
          threshold: 0.7,
        }
      );

      const arrayOfMessageLi = scrollContainer.value?.querySelectorAll(
        "[data-elementtype='message']"
      );
      if (arrayOfMessageLi) {
        for (let i = 0; i < arrayOfMessageLi?.length; ++i) {
          const messageElement = arrayOfMessageLi[i];
          const matchingMessage = data.messageList.find((message) => {
            return messageElement.id === message.ID;
          });
          if (
            matchingMessage &&
            matchingMessage.needReadReceipt &&
            matchingMessage.flow === "in"
          ) {
            mappingFromIDToMessage[messageElement.id] = {
              msgDom: messageElement,
              msgModel: matchingMessage,
            };
            observer?.observe(messageElement);
          }
        }
      }
    }

    //列表滚动
    const scrollToPosition = async (config) =>
      new Promise((resolve, reject) => {
        requestAnimationFrame(() => {
          if (!scrollContainer.value) {
            reject();
          }
          const container = scrollContainer.value;
          if (config.scrollToBottom) {
            container.scrollTop = container.scrollHeight;
          } else if (config.scrollToOffset) {
            if (typeof config.scrollToOffset?.top === "number") {
              container.scrollTop = config.scrollToOffset.top;
            } else if (config.scrollToOffset?.bottom) {
              container.scrollTop =
                container.scrollHeight - config.scrollToOffset.bottom;
            }
          }
          resolve(0);
        });
      });

    // 消息列表变化的处理
    watch(
      () => data.messageList,
      async (newValue) => {
        if (data.activeSession.status === SESSION_STATUS.FINISHED) {
          return;
        }
        observer?.disconnect();
        const oldLastMessage = currentLastMessage.value;
        data.messageList.value = newValue.filter((message) => {
          return !message.isDeleted;
        });
        if (!data.messageList.value?.length) {
          currentLastMessage.value = {};
          return;
        }
        const newLastMessage =
          data.messageList.value?.[data.messageList.value?.length - 1];
        if (beforeHistoryGetScrollHeight.value) {
          await scrollToPosition({
            scrollToOffset: { bottom: beforeHistoryGetScrollHeight.value },
          });
          beforeHistoryGetScrollHeight.value = 0;
        } else if (
          data.isScrollOverOneScreen &&
          newLastMessage?.flow === "in"
        ) {
          return;
        } else if (
          newLastMessage?.ID &&
          JSON.stringify(oldLastMessage) !== JSON.stringify(newLastMessage)
        ) {
          await scrollToPosition({ scrollToBottom: true });
        }
        currentLastMessage.value = Object.assign({}, newLastMessage);
        nextTick(() => bindIntersectionObserver());
      },
      { deep: true }
    );

    // 会话变化的处理
    watch(
      () => data.activeSession,
      async (newValue) => {
        data.isScrollOverOneScreen = false;
        if (newValue.status === SESSION_STATUS.FINISHED) {
          await scrollToPosition({
            scrollToOffset: { top: 0 },
          });
        }
      },
      { deep: true }
    );

    // 消息列表向上的滚动高度大于一屏时，展示滚动到最新
    async function judgeScrollOverOneScreen(e) {
      if (e.target) {
        try {
          const scrollContainer = document.getElementById("scrollContainer");
          if (scrollContainer != null) {
            const { height } = document
              .getElementById("scrollContainer")
              .getBoundingClientRect();
            const scrollHeight = e.target?.scrollHeight;
            const scrollTop = e.target?.scrollTop || 0;
            if (scrollHeight - scrollTop > 2 * height) {
              data.isScrollOverOneScreen =
                true &&
                data?.activeSession?.status === SESSION_STATUS.IN_PROGRESS;
              return;
            }
          }
          // 没有就不展示
          data.isScrollOverOneScreen = false;
        } catch (error) {
          data.isScrollOverOneScreen = false;
        }
      }
    }

    const handelScrollListScroll = throttle(
      function (e) {
        judgeScrollOverOneScreen(e);
      },
      150,
      { leading: true }
    );

    const scrollToMessageListBottom = () => {
      const scrollContainer = document.querySelector(".messageListContainer");
      if (scrollContainer) {
        scrollContainer?.lastElementChild?.scrollIntoView({ block: "end" });
      }
    };

    // 绑定监听滚动事件 展示scroll-button
    onMounted(() => {
      scrollContainer.value?.addEventListener("scroll", handelScrollListScroll);
    });

    onUnmounted(() => {
      scrollContainer.value?.removeEventListener(
        "scroll",
        handelScrollListScroll
      );
    });

    return {
      ...toRefs(data),
      isReverseMessage,
      messageListScroll,
      scrollContainer,
      target,
      isInfoMessage,
      openActions,
      scrollToMessageListBottom,
    };
  },
});
</script>

<style lang="scss" scoped>
.outerContainer {
  position: relative;
  flex: 1;
  overflow: hidden hidden;
}

.messageListContainer {
  padding: 0;
  overflow: hidden auto;
  height: 100%;

  .messageContainer {
    flex-direction: column;
    display: flex;
    flex-wrap: nowrap;
    margin-top: 30px;
    padding: 0px 20px 10px 20px;
  }

  .messageContainerReverse {
    flex-direction: column;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    margin-top: 30px;
    padding: 0px 20px 10px 20px;
  }

  .revokeMessage {
    text-align: center;
    color: #999;
    font-size: 12px;
    margin-top: 10px;
    padding-bottom: 10px;
  }
}
</style>
