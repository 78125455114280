<template>
  <span class="upload-btn icon icon-files">
    <input
      title="文件"
      type="file"
      data-type="file"
      accept="*"
      @change="sendUploadMessage"
    />
  </span>
</template>

<script>
import { defineComponent, inject, reactive, toRefs, watchEffect } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "FileUploadButton",
  props: {
    activeSession: {
      type: Object,
      default: () => null,
    },
  },
  setup(props) {
    const data = reactive({
      tcccSdk: null,
      activeSession: {},
    });
    const store = useStore();
    const tcccsdk = inject("tcccsdk");
    watchEffect(() => {
      data.activeSession = props.activeSession;
      data.tcccSdk = tcccsdk.value;
    });
    const sendUploadMessage = async (e) => {
      if (e.target.files.length > 0) {
        try {
          if (data.tcccSdk && data.activeSession?.sessionId) {
            const createFileMessage = await data.tcccSdk.Chat.createFileMessage(
              {
                sessionId: data.activeSession.sessionId,
                payload: { file: e.target.files[0] },
              }
            );
            await data.tcccSdk.Chat.sendMessage(createFileMessage.data);
            store.commit("message/insertMessageIntoMessageList", {
              message: createFileMessage.data,
              selectedSession: data.activeSession,
            });
            store.commit("session/updateSessionsLastMessage", {
              type: "receiveMessage",
              message: createFileMessage.data,
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
      e.target.value = "";
    };
    return {
      ...toRefs(data),
      sendUploadMessage,
    };
  },
});
</script>

<style lang="scss" scoped>
.upload-btn {
  position: relative;

  input {
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}

.icon {
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
}

.icon-files {
  margin: 12px 10px 0;
  background: url("../../../../assets/icon/files.png") no-repeat;
  background-size: contain;
}
</style>
