<template>
  <div class="infoMessage">
    <div v-if="payload.src === CUSTOM_MESSAGE_SRC.MENU">已成功下发评价</div>
    <div v-else-if="payload.src === CUSTOM_MESSAGE_SRC.MENU_SELECTED">
      用户已填写评价
    </div>
  </div>
</template>

<script>
import { defineComponent, watchEffect, reactive, toRefs } from "vue";
import { JSONToObject } from "../../../../utils/index";
import { CUSTOM_MESSAGE_SRC } from "../../../../constants/index";

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const data = reactive({
      data: {},
      payload: {},
    });

    watchEffect(() => {
      data.data = props.data;
      data.payload = JSONToObject(data.data?.payload?.data);
    });

    return {
      ...toRefs(data),
      CUSTOM_MESSAGE_SRC,
    };
  },
});
</script>

<style lang="scss" scoped>
.infoMessage {
  text-align: center;
  color: #999;
  font-size: 12px;
  margin-top: 10px;
  padding-bottom: 10px;
}
</style>
