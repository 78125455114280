<template>
  <div class="message-video">
    <video
      :class="[isWidth ? 'isWidth' : 'isHeight']"
      :src="data.url"
      controls
    ></video>
  </div>
</template>

<script>
import { defineComponent, watchEffect, reactive, toRefs, computed } from "vue";

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    isH5: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const data = reactive({
      data: {},
      show: false,
    });

    watchEffect(() => {
      data.data = props.data;
    });

    const isWidth = computed(() => {
      const { snapshotWidth = 0, snapshotHeight = 0 } =
        data.data.message.payload;
      return snapshotWidth >= snapshotHeight;
    });

    const toggleShow = () => {
      if (data.data?.progress) {
        data.show = !data.show;
      }
    };

    return {
      ...toRefs(data),
      toggleShow,
      isWidth,
    };
  },
});
</script>
<style lang="scss" scoped>
.message-video {
  overflow: hidden;
  position: relative;
  video {
    max-width: min(calc(100vw - 180px), 300px);
    max-height: min(calc(100vw - 180px), 300px);
  }
}
.isWidth {
  width: 100%;
}

.isHeight {
  height: 100%;
}
</style>
