import TIM from "@tencentcloud/chat";
import { decodeText } from "./decodeText";
import { CUSTOM_MESSAGE_SRC, PEER_SOURCE } from "../constants/index";

export function isJSON(str) {
  if (typeof str === "string") {
    try {
      const data = JSON.parse(str);
      if (data) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }
  return false;
}

// Determine if it is a JSON string
export function JSONToObject(str) {
  if (!isJSON(str)) {
    return str;
  }
  return JSON.parse(str);
}

export function isCustomerServiceMessage(message) {
  const customerServicePayload = JSONToObject(message?.payload?.data);
  if (customerServicePayload?.customerServicePlugin === 0) {
    return true;
  }
  return false;
}

export const isCustomServiceMessageInvisible = (message) => {
  const customerServicePayload = JSONToObject(message?.payload?.data);
  const robotCommandArray = ["feedback", "updateBotStatus"];
  const whiteList = [
    CUSTOM_MESSAGE_SRC.MENU,
    CUSTOM_MESSAGE_SRC.MENU_SELECTED,
    CUSTOM_MESSAGE_SRC.BRANCH,
    CUSTOM_MESSAGE_SRC.FROM_INPUT,
    CUSTOM_MESSAGE_SRC.PRODUCT_CARD,
    CUSTOM_MESSAGE_SRC.ORDER_CARD,
  ];
  const isCustomerMessage = message?.type === TIM.TYPES.MSG_CUSTOM;
  const isExistWhiteList = whiteList.includes(customerServicePayload?.src);
  const isRobot =
    customerServicePayload?.src === CUSTOM_MESSAGE_SRC.ROBOT &&
    robotCommandArray.indexOf(customerServicePayload?.content?.command) !== -1;
  return isCustomerMessage && (!isExistWhiteList || isRobot);
};

export const isMessageRating = (message) => {
  const customerServicePayload = JSONToObject(message?.payload?.data);
  if (
    message?.type === TIM.TYPES.MSG_CUSTOM &&
    customerServicePayload?.customerServicePlugin === 0 &&
    customerServicePayload.src === CUSTOM_MESSAGE_SRC.MENU
  ) {
    return true;
  }
  return false;
};

export function isPluginMessage(message) {
  return (
    message.type === TIM.TYPES.MSG_CUSTOM && isCustomerServiceMessage(message)
  );
}

export const getSessionUserShowName = (session) => {
  if (
    session?.clientData &&
    session?.clientData !== null &&
    typeof session?.clientData === "object"
  ) {
    try {
      const clientDataObj = JSON.parse(JSON.stringify(session?.clientData));
      if (clientDataObj?.nickName && clientDataObj?.nickName !== "") {
        return clientDataObj?.nickName;
      }
    } catch (e) {
      console.log(e);
    }
  }
  if (typeof session?.clientData === "string" && session?.clientData !== "") {
    try {
      return JSON.parse(session?.clientData)?.nickName || "";
    } catch (e) {
      console.log(e);
    }
  }
  return session.nickname === "" ? session.userId : session.nickname;
};

export const getSessionUserShowSource = (session) => {
  if (
    session?.clientData &&
    session?.clientData !== null &&
    typeof session?.clientData === "object"
  ) {
    try {
      const clientDataObj = JSON.parse(JSON.stringify(session?.clientData));
      if (clientDataObj?.peerSource && clientDataObj?.peerSource !== "") {
        return clientDataObj?.peerSource;
      }
    } catch (e) {
      console.log(e);
    }
  }
  if (typeof session?.clientData === "string" && session?.clientData !== "") {
    try {
      return JSON.parse(session?.clientData)?.peerSource || "";
    } catch (e) {
      console.log(e);
    }
  }
  return session.peerSource ? PEER_SOURCE[session.peerSource] : "未知来源";
};

export const getSessionMessageShowName = (session, userID, message) => {
  if (message?.flow === "in" && message?.from !== "administrator") {
    return getSessionUserShowName(session);
  }
  return userID;
};

export const getSessionClientDataObj = (session) => {
  if (session?.clientData) {
    try {
      let clientDataObj = {};
      if (typeof session?.clientData === "object") {
        clientDataObj = JSON.parse(JSON.stringify(session?.clientData));
      }
      if (typeof session?.clientData === "string") {
        clientDataObj = JSON.parse(session?.clientData);
      }
      return clientDataObj;
    } catch (e) {
      console.log(e);
    }
  }
  return {};
};

export const formateFileSize = function (fileSize) {
  let ret = "";
  if (fileSize >= 1024 * 1024) {
    ret = `${(fileSize / (1024 * 1024)).toFixed(2)} Mb`;
  } else if (fileSize >= 1024) {
    ret = `${(fileSize / 1024).toFixed(2)} Kb`;
  } else {
    ret = `${fileSize.toFixed(2)}B`;
  }
  return ret;
};

// Parsing and handling text message display
export function handleTextMessageShowContext(item) {
  const options = {
    text: decodeText(item.payload),
  };

  return options;
}

// Parsing and handling face message display
export function handleFaceMessageShowContext(item) {
  const face = {
    message: item,
    name: "",
    url: "",
  };
  face.name = item.payload.data;
  if (item.payload.data.indexOf("@2x") < 0) {
    face.name = `${face.name}@2x`;
  }
  face.url = `https://web.sdk.qcloud.com/im/assets/face-elem/${face.name}.png`;
  return face;
}

// Parsing and handling image message display
export function handleImageMessageShowContext(item) {
  return {
    progress: item?.status === "unSend" && item.progress,
    url: item.payload.imageInfoArray[1].url,
    message: item,
  };
}

// Parsing and handling video message display
export function handleVideoMessageShowContext(item) {
  return {
    progress: item?.status === "unSend" && item?.progress,
    url: item?.payload?.videoUrl,
    snapshotUrl: item?.payload?.snapshotUrl,
    message: item,
  };
}

// Parsing and handling audio message display
export function handleAudioMessageShowContext(item) {
  return {
    progress: item?.status === "unSend" && item.progress,
    url: item.payload.url || item.payload.remoteAudioUrl,
    message: item,
    second: item.payload.second,
  };
}

// Parsing and handling file message display
export function handleFileMessageShowContext(item) {
  let size = "";
  if (item.payload.fileSize >= 1024 * 1024) {
    size = `${(item.payload.fileSize / (1024 * 1024)).toFixed(2)} Mb`;
  } else if (item.payload.fileSize >= 1024) {
    size = `${(item.payload.fileSize / 1024).toFixed(2)} Kb`;
  } else {
    size = `${item.payload.fileSize.toFixed(2)}B`;
  }
  return {
    progress: item?.status === "unSend" && item.progress,
    url: item.payload.fileUrl,
    message: item,
    name: item.payload.fileName,
    size,
  };
}

// Parsing and handling merger message display
export function handleMergerMessageShowContext(item) {
  return { message: item, ...item.payload };
}

export const handleCustomMessage = (message) => message.payload;

// 历史消息格式化
export const formateHistoryMessage = (message, userId) => {
  const res = {
    time: Number(message?.timestamp) / 1000 ?? 0,
    avatar: "",
    flow: "",
    type: "",
    payload: {},
  };
  const formateData = JSON.parse(message.detail);
  res.type = formateData.MsgBody[0].MsgType;
  if (res.type === TIM.TYPES.MSG_TEXT) {
    res.payload = {
      text: formateData.MsgBody[0].MsgContent.Text,
    };
  }
  if (res.type === TIM.TYPES.MSG_CUSTOM) {
    res.payload = {
      data: formateData.MsgBody[0].MsgContent.Data,
      description: formateData.MsgBody[0].MsgContent.Desc,
      extension: formateData.MsgBody[0].MsgContent.Ext,
    };
  }
  if (res.type === TIM.TYPES.MSG_IMAGE) {
    res.payload = {
      imageInfoArray: [
        {
          height: formateData.MsgBody[0].MsgContent.ImageInfoArray[0].Height,
          url: formateData.MsgBody[0].MsgContent.ImageInfoArray[0].URL,
          width: formateData.MsgBody[0].MsgContent.ImageInfoArray[0].Width,
          size: formateData.MsgBody[0].MsgContent.ImageInfoArray[0].Size,
          type: formateData.MsgBody[0].MsgContent.ImageInfoArray[0].Type,
        },
        {
          height: formateData.MsgBody[0].MsgContent.ImageInfoArray[1].Height,
          url: formateData.MsgBody[0].MsgContent.ImageInfoArray[1].URL,
          width: formateData.MsgBody[0].MsgContent.ImageInfoArray[1].Width,
          size: formateData.MsgBody[0].MsgContent.ImageInfoArray[1].Size,
          type: formateData.MsgBody[0].MsgContent.ImageInfoArray[1].Type,
        },
        {
          height: formateData.MsgBody[0].MsgContent.ImageInfoArray[2].Height,
          url: formateData.MsgBody[0].MsgContent.ImageInfoArray[2].URL,
          width: formateData.MsgBody[0].MsgContent.ImageInfoArray[2].Width,
          size: formateData.MsgBody[0].MsgContent.ImageInfoArray[2].Size,
          type: formateData.MsgBody[0].MsgContent.ImageInfoArray[2].Type,
        },
      ],
      imageFormat: formateData.MsgBody[0].MsgContent.ImageFormat,
      uuid: formateData.MsgBody[0].MsgContent.UUID,
    };
  }
  if (res.type === TIM.TYPES.MSG_AUDIO) {
    res.payload = {
      uuid: formateData.MsgBody[0].MsgContent.UUID,
      url: formateData.MsgBody[0].MsgContent.Url,
      size: formateData.MsgBody[0].MsgContent.Size,
      second: formateData.MsgBody[0].MsgContent.Second,
    };
  }
  if (res.type === TIM.TYPES.MSG_VIDEO) {
    res.payload = {
      videoFormat: formateData.MsgBody[0].MsgContent.ThumbFormat,
      videoSecond: formateData.MsgBody[0].MsgContent.VideoSecond,
      videoSize: formateData.MsgBody[0].MsgContent.VideoSize,
      videoUrl: formateData.MsgBody[0].MsgContent.VideoUrl,
      videoUUID: formateData.MsgBody[0].MsgContent.VideoUUID,
      thumbWidth: formateData.MsgBody[0].MsgContent.ThumbWidth,
      thumbHeight: formateData.MsgBody[0].MsgContent.ThumbHeight,
      thumbUrl: formateData.MsgBody[0].MsgContent.ThumbUrl,
    };
  }
  if (res.type === TIM.TYPES.MSG_FILE) {
    res.payload = {
      uuid: formateData.MsgBody[0].MsgContent.UUID,
      fileName: formateData.MsgBody[0].MsgContent.FileName,
      fileSize: formateData.MsgBody[0].MsgContent.FileSize,
      fileUrl: formateData.MsgBody[0].MsgContent.Url,
    };
  }
  if (
    formateData.From_Account === "administrator" ||
    formateData.From_Account === userId
  ) {
    res.flow = "out";
  } else {
    res.flow = "in";
  }
  return res;
};

export const isInfoMessage = (message) => {
  try {
    const infoMessageList = [
      CUSTOM_MESSAGE_SRC.MENU,
      CUSTOM_MESSAGE_SRC.MENU_SELECTED,
    ];
    if (
      message?.type === TIM?.TYPES?.MSG_CUSTOM &&
      JSONToObject(message?.payload?.data)?.customerServicePlugin == 0 &&
      infoMessageList.includes(JSONToObject(message?.payload?.data)?.src)
    ) {
      return true;
    }
    return false;
  } catch (e) {
    console.log(e, "isInfoMessage");
    return false;
  }
};

export const isMessageVisible = (message) => {
  try {
    const customerServiceWhiteList = [
      CUSTOM_MESSAGE_SRC.BRANCH,
      CUSTOM_MESSAGE_SRC.FROM_INPUT,
      CUSTOM_MESSAGE_SRC.PRODUCT_CARD,
      CUSTOM_MESSAGE_SRC.ORDER_CARD,
      CUSTOM_MESSAGE_SRC.RICH_TEXT,
    ];
    if (isInfoMessage(message)) {
      return true;
    }
    if (
      message?.type === TIM?.TYPES?.MSG_CUSTOM &&
      JSONToObject(message?.payload?.data)?.customerServicePlugin == 0 &&
      !customerServiceWhiteList.includes(
        JSONToObject(message?.payload?.data)?.src
      )
    ) {
      return false;
    }
    return true;
  } catch (e) {
    console.log(e, "isMessageVisible");
    return true;
  }
};

export const formateLastMessage = (message) => {
  if (message.isRevoked) {
    if (message.flow === "in") {
      return "对方撤回了一条消息";
    }
    if (message.flow === "out") {
      return "你撤回了一条消息";
    }
  }
  if (message.type === TIM.TYPES.MSG_TEXT) {
    return message.payload.text;
  }
  if (message.type === TIM.TYPES.MSG_IMAGE) {
    return "[图片消息]";
  }
  if (message.type === TIM.TYPES.MSG_VIDEO) {
    return "[视频消息]";
  }
  if (message.type === TIM.TYPES.MSG_AUDIO) {
    return "[音频消息]";
  }
  if (message.type === TIM.TYPES.MSG_FILE) {
    return "[文件消息]";
  }
  if (message.type === TIM.TYPES.MSG_LOCATION) {
    return "[位置消息]";
  }
  if (message.type === TIM.TYPES.MSG_CUSTOM) {
    return "[自定义消息]";
  }
};

export function timestampToDateTime(timestamp, type) {
  const date = new Date(timestamp); // 如果时间戳是毫秒级别的，不需要乘以1000
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // 月份是从0开始的，所以要加1
  const day = ("0" + date.getDate()).slice(-2);
  const hour = ("0" + date.getHours()).slice(-2);
  const minute = ("0" + date.getMinutes()).slice(-2);
  const second = ("0" + date.getSeconds()).slice(-2);
  if (type == "full") {
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  } else if (type == "day") {
    return `${year}-${month}-${day}`;
  } else {
    return `${hour}:${minute}:${second}`;
  }
}
