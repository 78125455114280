<template>
  <div class="upload-btn icon icon-transfer" @click="clickTransfer"></div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";

export default defineComponent({
  name: "TransferButton",
  setup(props, ctx) {
    const data = reactive({});
    const clickTransfer = () => {
      ctx.emit("clickTransfer");
    };
    return {
      ...toRefs(data),
      clickTransfer,
    };
  },
});
</script>

<style lang="scss" scoped>
.upload-btn {
  position: relative;
}

.icon {
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
}

.icon-transfer {
  margin: 11px 10px 1px 10px;
  background: url("../../../../assets/icon/Transfer.svg");
  background-size: contain;
}
</style>
