<template>
  <div style="flex: 1">
    <div class="topActions">
      <el-popover placement="bottom" :visible="actionVisible">
        <template #reference>
          <img
            class="messageAvatar"
            src="https://qcloudimg.tencent-cloud.cn/raw/9c6b6806f88ee33b3685f0435fe9a8b3.png"
            @click="openActions"
          />
        </template>
        <div class="action" @click="free">示闲</div>
        <div class="action" @click="busy">示忙</div>
        <div class="action" @click="online">上线</div>
        <div class="action" @click="logout">下线</div>
      </el-popover>
      <div style="color: rgb(255, 255, 255); opacity: 0.7; margin-right: 10px">
        {{ status }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  inject,
  reactive,
  toRefs,watch,
  watchEffect,
} from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "SideComponents",
  setup() {
    const data = reactive({
      tcccSdk: null,
      actionVisible: false,
      status: "空闲",
      statusMap: {
        // 0：离线 100：空闲 200:忙碌 300:休息 700:示忙
        0: "离线",
        100: "空闲",
        200: "忙碌",
        300: "休息",
        700: "示忙",
        ready: "空闲",
        offline: "离线",
        free: "空闲",
        busy: "忙碌",
        rest: "休息",
        notReady: "示忙",
      },
    });
    const tcccsdk = inject("tcccsdk");
    const store = useStore();
    const agentInfo = computed(() => store.state.agent.agentInfo);

    watchEffect(async () => {
      data.tcccSdk = tcccsdk.value;
    });

    watchEffect(async () => {
      console.log(agentInfo.value,data.tcccSdk, "agentInfo");
      data.status = data.statusMap[agentInfo.value.status];
    });
    watch(
    agentInfo,
    (newVal, oldVal) => {
       console.log(newVal, oldVal,"watch")
    })

    // 示闲 
    const free = async () => {
      if (data.status === "离线") return;
      if (!data.tcccSdk) return;
      await data.tcccSdk.Agent.setStatus("free");
      data.actionVisible = false;
    };
    // 示忙
    const busy = async () => {
      if (data.status === "离线") return;
      if (!data.tcccSdk) return;
      await data.tcccSdk.Agent.setStatus("notReady");
      data.actionVisible = false;
    };
    // 下线
    const logout = async () => {
      if (!data.tcccSdk) return;
      await data.tcccSdk.Agent.offline();
      data.actionVisible = false;
    };
    // 上线
    const online = async () => {
      if (!data.tcccSdk) return;
      await data.tcccSdk.Agent.online();
      data.actionVisible = false;
    };
    const openActions = () => {
      data.actionVisible = !data.actionVisible;
    };
    return {
      ...toRefs(data),
      free,
      busy,
      online,
      logout,
      openActions,
    };
  },
});
</script>

<style lang="scss" scoped>
.topActions {
  height: 56px;
  background: #2b303b;
  display: flex;
  align-items: center;

  .messageAvatar {
    margin-left: auto;
    margin-right: 10px;
    width: 36px;
    height: 36px;
  }
}

.action {
  padding: 4px 6px;
  font-size: 14px;
  width: 100px;
  display: flex;
  justify-content: space-between;
}

.action:hover {
  background: #f2f7ff;
}
</style>
