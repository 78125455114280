<template>
  <div v-for="item in selfSkillGroup" :key="item.skillGroupId" class="skillGroupUnCallIn">
    {{ item.skillGroupName }}: {{ item.count }}人
  </div>
  <div v-for="item in queuingList" :key="item.sessionId">
    <UserItem :userInfo="item" :type="'queuing'"></UserItem>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  inject,
  reactive,
  toRefs,
  watchEffect,
} from "vue";
import UserItem from "../UserItem/index.vue";
import { useStore } from "vuex";
import { getSelfSkillGroup } from "../../../../utils/api";

export default defineComponent({
  name: "QueuingList",
  components: {
    UserItem,
  },
  props: {
    queuingList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const data = reactive({
      queuingList: [],
      tcccSdk: null,
      selfSkillGroup: [],
      userInfo: computed(() => store?.state?.agent?.agentInfo),
    });
    const tcccsdk = inject("tcccsdk");
    const store = useStore();
    watchEffect(() => {
      data.queuingList = props.queuingList;
    });
    watchEffect(() => {
      if (data?.userInfo?.queueCount) {
        Object.keys(data?.userInfo?.queueCount)?.forEach((key) => {
          data.selfSkillGroup.map((item) => {
            if (item.skillGroupId + "" === key + "") {
              item.count = data?.userInfo?.queueCount[key] ?? 0;
            }
          });
        });
      }
    });
    watchEffect(async () => {
      data.tcccSdk = tcccsdk.value;
      if (data.tcccSdk) {
        const initSDKRes = JSON.parse(sessionStorage.getItem('sdkconfig'))
        let params = {
          PageSize: 100,
          PageNumber: 0,
          SdkAppId: initSDKRes.sdkAppId,
          StaffMail: initSDKRes.userId,
        }
        const selfSkillGroupRes = (await getSelfSkillGroup(params)).data.StaffList[0].SkillGroupList;
        data.selfSkillGroup = selfSkillGroupRes?.map((item) => {
          return {
            skillGroupId: item.SkillGroupId,
            skillGroupName: item.SkillGroupName,
            count: 0,
          };
        });
      }
    });
    return {
      ...toRefs(data),
    };
  },
});
</script>

<style lang="scss" scoped>
.skillGroupUnCallIn {
  color: balck;
  margin: 5px 0 5px 20px;
  font-weight: 600;
  font-size: 12px;
}
</style>
