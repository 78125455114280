const state = {
  // 当前应用的配置
  appSetting: {
    callTimeout: 15,
    imTimeout: 15,
    audioTimeout: 15,
    videoTimeout: 15,
    endCountdown: 15,
    imEndCountdown: 15,
    audioEndCountdown: 15,
    imAutoAccept: false,
    telAutoAccept: false,
    audioAutoAccept: false,
    videoAutoAccept: false,
    hideCalloutNumber: false,
    hideCallinNumber: false,
    numberReflectMode: false,
    seatType: 4,
    smsPrivilege: false,
    autoRealtimeAsr: false,
    realtimeAsr: false,
    telBlackUserEnable: false,
    staffAssignedCalloutNumber: false,
    skillGroupMap: {},
    restReasonList: [],
    imContactUserEnable: false,
  },
};

const mutations = {
  // 初始化tccc实例属性
  initAppInfo(state, appSetting) {
    state.appSetting = {
      ...state.appSetting,
      ...appSetting,
    };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
