import { createApp } from "vue";
import App from "./App.vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import store from "./store";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import router from './router'



const app = createApp(App);



app.use(store).use(router).use(ElementPlus, { locale: zhCn }).mount("#app");
