<template>
  <div class="OrderContainer">
    <div v-if="payload?.content?.guide" class="GuideText">
      {{ payload?.content?.guide?.toString() }}
    </div>
    <div class="OrderInfoContainer">
      <img
        :src="payload?.content?.pic"
        style="width: 60px; height: 60px; object-fit: fill"
      />
      <div style="margin-left: 5px">
        <div class="OrderName">{{ payload?.content?.name?.toString() }}</div>
        <div class="OrderDesc">{{ payload?.content?.desc?.toString() }}</div>
      </div>
    </div>
    <div class="CustomFieldContainer" v-if="isCustomFieldArray">
      <div
        v-for="(item, index) in payload?.content?.customField"
        :key="index"
        style="display: flex; padding: 10px"
      >
        <div style="font-size: 14px; font-weight: bold; word-break: keep-all">
          {{ item?.name?.toString() }}
        </div>
        <div
          :style="{
            wordBreak: 'break-all',
            fontSize: '14px',
            fontWeight: 'bold',
            color: item?.valueColor,
          }"
        >
          {{ item?.value?.toString() }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs, watchEffect } from "vue";
export default defineComponent({
  props: {
    payload: {
      type: Object,
      default: () => ({}),
    },
    message: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const data = reactive({
      data: {},
      extension: {},
      payload: {},
      message: {},
    });

    watchEffect(() => {
      data.payload = props.payload;
      data.message = props.message;
    });

    const isCustomFieldArray = computed(() => {
      return Array.isArray(data.payload?.content?.customField);
    });

    return {
      ...toRefs(data),
      isCustomFieldArray,
    };
  },
});
</script>
<style lang="scss" scoped>
.OrderContainer {
  max-width: 300px;
  min-width: 300px;
  width: 300px;
  background-color: white;
  border: 1px solid #999999;
  border-radius: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  .GuideText {
    font-size: 18px;
    color: black;
    font-weight: bold;
    word-break: break-all;
    padding: 10px;
  }
  .OrderInfoContainer {
    display: flex;
    background: #f9f9f9;
    padding: 10px;
    .OrderName {
      font-size: 16px;
      font-weight: bold;
      word-break: break-all;
      color: black;
    }
    .OrderDesc {
      font-size: 14px;
      font-weight: bold;
      word-break: break-all;
      color: #999999;
    }
  }
}
</style>
