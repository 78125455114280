<template>
    <div class="pageApp">
      <SideComponents></SideComponents>
      <div class="workspace">
        <ConversationList></ConversationList>
        <Chat></Chat>
        <RightIndex></RightIndex>
      </div>
    </div>
  </template>
  
  <script>
  import { onMounted, ref, provide, reactive, toRefs, computed } from "vue";
  import { useStore } from "vuex";
  import ConversationList from "../components/ConversationList/index.vue";
  import Chat from "../components/Chat/index.vue";
  import SideComponents from "../components/SideComponents/index.vue";
  import RightIndex from "../components/RightIndex/index.vue"
  import { SESSION_STATUS } from "../constants/index";
  import { isMessageVisible } from "../utils/index";
  import { ElMessageBox } from "element-plus";
  import { LoginApi } from "../utils/api"
    
  
  export default {
    name: "Home",
    components: {
      ConversationList,
      Chat,
      SideComponents,
      RightIndex,
    },
    setup() {
      const data = reactive({
        sdk: null,
      });
      const tcccsdk = ref();
      const store = useStore();
      const activeSession = computed(() => store.state.session.selectedSession);
      // 获取外部会话列表
      const getSessionList = async () => {
        const res = await data.sdk.Chat.getSessionList();
        store.commit(
          "session/initOuterSessionList",
          res.data.filter((item) => {
            return item.type === "im";
          }) ?? []
        );
      };
      // 获取内部会话列表
      const getInnerConversationList = async () => {
        const res = await data.sdk.Chat.getInnerConversationList();
        store.commit("session/initInnerSessionList", res.data ?? []);
      };
  
      // 收到消息回调
      const onMessageReceived = async (message) => {
        if (store.state.session?.selectedSession) {
          if (
            message.data[0]?.sessionId ===
            store.state.session?.selectedSession.sessionId
          ) {
            await data.sdk.Chat.setMessageRead({
              sessionId: store.state.session?.selectedSession.sessionId,
            });
          }
        }
        if (!isMessageVisible(message.data[0])) return;
        store.commit("session/increaseOuterSessionUnreadCount", message.data[0]);
        store.commit("message/insertMessageIntoMessageList", {
          message: message.data[0],
          selectedSession: store.state.session?.selectedSession,
        });
        store.commit("session/updateSessionsLastMessage", {
          type: "receiveMessage",
          message: message.data[0],
        });
      };
  
      // 会话呼入监听
      const onSessionCallIn = async (session) => {
        if (session.type !== "im") {
          return;
        }
        session.status = SESSION_STATUS.RINGING;
        session.unreadCount = 0;
        session.timestamp = Date.now() / 1000;
        store.commit("session/insertOuterSessionList", session);
        if (store.state.appSetting?.appSetting?.imAutoAccept) {
          await data.sdk.Chat.seatInChat({
            sessionId: session.sessionId,
          });
          store.commit("session/acceptOuterSessionList", session);
        }
      };
  
      // 消息撤回监听
      const onMessageRevoked = (event) => {
        store.commit("message/updateRevokeMessage", event.data[0]?.messageId);
        store.commit("session/updateSessionsLastMessage", {
          type: "revokeMessage",
          message: {
            ID: event.data[0].messageId,
            sessionId: event.data[0].sessionId,
          },
        });
      };
  
      // 会话结束的监听
      const onSessionEnded = (session) => {
        store.commit("session/handleUnnormalSessionEnd", session);
      };
  
      // 座席排队状态改变监听
      const onAgentStateChanged = (option) => {
        console.log(option, "onAgentStateChanged");
        store.commit("agent/updateAgentInfo", { queueCount: option.queueCount });
      };
  
      // 座席状态改变监听
      const onStatusChanged = (option) => {
        console.log(option, "onStatusChanged");
        store.commit("agent/updateAgentInfo", { status: option.status });
      };
  
      // 消息评价成功的事件
      const onRatingSucceeded = (option) => {
        if (activeSession?.value?.sessionId === option?.sessionId) {
          store.commit("message/insertMessageIntoMessageList", {
            message: option?.message,
            selectedSession: activeSession.value,
          });
        }
      };
  
      // 账号被踢出监听
      const onKickedOut = () => {
        ElMessageBox.alert("您的账号已在其他地方登录", "提示", {
          confirmButtonText: "重新登录",
          callback: () => {
            data.sdk.Agent.online()
              .then(() => {
                getSessionList();
                getInnerConversationList();
              })
              .catch((e) => {
                ElMessageBox.alert(e.message, "登录失败", {
                  confirmButtonText: "关闭",
                });
              });
          },
        });
      };
  
      // 登录票据过期监听
      const onExpired = () => {
        ElMessageBox.alert("您的登录已过期，请重新登录", "提示", {
          confirmButtonText: "确定",
        });
      };
  
      // 被强制下线监听
      const onForcedOffline = () => {
        ElMessageBox.alert("您的账号被强制下线，请联系管理员", "提示", {
          confirmButtonText: "确定",
        });
      };
  
      // 添加监听事件
      const addTcccEventListener = () => {
        // 内部会话新增放在收消息回调里面处理
        console.log("12312312123",data.sdk)
        data.sdk.on("messageReceived", onMessageReceived);
        data.sdk.on("callIn", onSessionCallIn);
        data.sdk.on("agentStateChanged", onAgentStateChanged);
        data.sdk.on("onMessageRevoked", onMessageRevoked);
        data.sdk.on("sessionEnded", onSessionEnded);
        data.sdk.on("ratingSucceeded", onRatingSucceeded);
        data.sdk.on("kickedOut", onKickedOut);
        data.sdk.on("expired", onExpired);
        data.sdk.on("forcedOffline", onForcedOffline);
        data.sdk.on("statusChanged", onStatusChanged);
      };
  
      const initActions = async () => {
        getSessionList();
        getInnerConversationList();
        addTcccEventListener();
      };
      const initSDK = async () => {
        try {
          // 发送 fetch 请求获取 token
        let params = JSON.parse( sessionStorage.getItem('ruleForm'))
        const initSDKRes = await LoginApi(params) 
        const initSDKResData = initSDKRes.data
        // const initSDKRes = await fetch("/loginTCCC");
        // const initSDKResData = await initSDKRes.json();
        const scriptDom = document.createElement("script");
        scriptDom.setAttribute("crossorigin", "anonymous");
        scriptDom.dataset.token = initSDKResData.token;
        scriptDom.dataset.sdkAppId = initSDKResData.sdkAppId;
        scriptDom.dataset.userid = initSDKResData.userId;
        scriptDom.dataset.disableUI = true;
        scriptDom.src = initSDKResData.sdkUrl;
        document.body.appendChild(scriptDom);
        scriptDom.addEventListener("load", () => { 
          // ready事件必须监听，否则容易发生tccc不存在的错误，所有逻辑必须在ready事件触发后才可以调用
          window.tccc.on("ready", () => {
            console.log(window.tccc,"window.tccc");
            data.sdk = window.tccc;
            tcccsdk.value = window.tccc;
            initActions();
          });
          window.tccc.on("loginSuccess", (data) => {
            console.log(data);
            store.commit("agent/updateAgentInfo",{ status:"free"});
            store.commit("appSetting/initAppInfo", data.appSettings);
            store.commit("userInfo/updateUserInfo", data.userInfo);
          });
          });
        } catch (error) {
          console.error(`获取Token失败：${error.message}`);
        }
      };
      onMounted(async () => {
          initSDK();
      });
      provide("tcccsdk", tcccsdk);
      return {
        ...toRefs(data),
      };
    },
  };
  </script>
  
  <style lang="scss">
  .pageApp{
    display: flex;
    flex-direction: column;
  }
  .workspace {
    display: flex;
  }
  
  body {
    margin: 0;
  }
  </style>
  