<template>
  <div class="quoteMessageContainer">
    <div>{{ formateQuoteMessage(message) }}:</div>
    <div v-if="message?.type === types.MSG_TEXT" class="quoteMessage">
      {{ message.payload.text }}
    </div>
    <div v-if="message?.type === types.MSG_IMAGE" class="quoteMessage">
      图片消息
    </div>
    <div v-if="message?.type === types.MSG_VIDEO" class="quoteMessage">
      视频消息
    </div>
    <div v-if="message?.type === types.MSG_AUDIO" class="quoteMessage">
      音频消息
    </div>
    <div v-if="message?.type === types.MSG_FILE" class="quoteMessage">
      文件消息
    </div>
    <div v-if="message?.type === types.MSG_LOCATION" class="quoteMessage">
      位置消息
    </div>
    <div v-if="message?.type === types.MSG_CUSTOM" class="quoteMessage">
      自定义消息
    </div>
    <img
      class="cancelButton"
      src="../../../../assets/icon/cancel.png"
      @click="cancelQuote"
    />
  </div>
</template>

<script>
import TIM from "@tencentcloud/chat";
import { defineComponent, reactive, toRefs, watchEffect, computed } from "vue";
import { useStore } from "vuex";
import { getSessionUserShowName } from "../../../../utils/index";

export default defineComponent({
  name: "QuoteMessage",
  props: {
    message: {
      type: Object,
      default: () => null,
    },
  },
  setup(props) {
    const data = reactive({
      message: null,
      types: TIM.TYPES,
    });
    const store = useStore();
    watchEffect(() => {
      data.message = props.message;
    });

    const activeSession = computed(() => store.state.session.selectedSession);
    const userInfo = computed(() => store.state.userInfo.userInfo);

    const quoteMessageUserName = (quoteMessage) => {
      return quoteMessage?.flow === "in" &&
        quoteMessage?.from !== "administrator" &&
        quoteMessage?.from === activeSession.value?.userId
        ? getSessionUserShowName(activeSession.value)
        : quoteMessage?.from === userInfo.value?.userId
          ? userInfo.value?.userId
          : quoteMessage?.from;
    };

    const formateQuoteMessage = (message) => {
      if (message.type === data.types.MSG_TEXT) {
        return quoteMessageUserName(message) + ":" + message.payload.text;
      }
      if (message.type === data.types.MSG_IMAGE) {
        return quoteMessageUserName(message) + ":" + "[图片消息]";
      }
      if (message.type === data.types.MSG_VIDEO) {
        return quoteMessageUserName(message) + ":" + "[视频消息]";
      }
      if (message.type === data.types.MSG_AUDIO) {
        return quoteMessageUserName(message) + ":" + "[音频消息]";
      }
      if (message.type === data.types.MSG_FILE) {
        return quoteMessageUserName(message) + ":" + "[文件消息]";
      }
      if (message.type === data.types.MSG_LOCATION) {
        return quoteMessageUserName(message) + ":" + "[位置消息]";
      }
      if (message.type === data.types.MSG_CUSTOM) {
        return quoteMessageUserName(message) + ":" + "[自定义消息]";
      }
    };

    const cancelQuote = () => {
      store.commit("message/updateQuoteMessage", undefined);
    };

    return {
      ...toRefs(data),
      cancelQuote,
      formateQuoteMessage,
    };
  },
});
</script>

<style lang="scss" scoped>
.quoteMessageContainer {
  display: flex;
  flex: 0 1 auto;
  background-color: #fafafa;
  border-radius: 8px;
  padding: 12px;
  font-size: 12px;
  align-items: center;
  line-height: 16px;
  box-sizing: border-box;
  min-width: 50px;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
}

.quoteMessage {
  font-size: 12px;
  align-items: center;
  line-height: 16px;
  box-sizing: border-box;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
}

.cancelButton {
  cursor: pointer;
  margin-left: 10px;
  display: inline-block;
}
</style>
