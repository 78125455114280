const state = {
  // 当前会话消息列表
  messageList: [],
  // 输入框引用的消息
  quoteMessage: undefined,
  // 快捷回复消息
  sending:undefined,
};

const mutations = {
  // 初始化消息列表
  initMessageList(state, messages) {
    state.messageList = messages;
  },
  // 插入消息列表
  insertMessageIntoMessageList(state, { message, selectedSession }) {
    if (selectedSession?.sessionId == message.sessionId) {
      state.messageList.push(message);
    }
  },
  // 更新消息列表中的撤回消息
  updateRevokeMessage(state, messageId) {
    state.messageList = state.messageList.map((item) => {
      if (item.ID === messageId) {
        item.isRevoked = true;
      }
      return item;
    });
  },
  // 更新消息列表
  updateMessageList(state, option) {
    // 服务中拉过去的会话
    if (option.type == "up") {
      state.messageList = option?.messages?.concat(state.messageList);
    }
    // 结束的会话往下拉会话
    if (option.type == "down") {
      state.messageList = state.messageList.concat(option?.messages);
    }
  },
  // 更新引用消息
  updateQuoteMessage(state, message) {
    state.quoteMessage = message;
  },
    // 发送快捷回复消息
  updateSending(state, message) {
      state.sending = message;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
