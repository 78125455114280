<template>
  <div style="width: 230px">
    <div v-for="item in innerSessionList" :key="item.sessionId">
      <UserItem :userInfo="item"></UserItem>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, computed } from "vue";
import { useStore } from "vuex";
import UserItem from "./UserItem/index.vue";

export default defineComponent({
  name: "InnerConversationList",
  components: {
    UserItem,
  },
  setup() {
    const data = reactive({});
    const store = useStore();
    const innerSessionList = computed(
      () => store.state.session.innerSessionList
    );
    return {
      ...toRefs(data),
      innerSessionList,
    };
  },
});
</script>

<style lang="scss" scoped></style>
