const state = {
  // 当前登录用户的信息
  userInfo: {
    userId: "",
    sdkAppId: "",
    staffName: "",
    nickname: "",
    canUseMobile: false,
    isBindMobile: false,
    useMobileCallOutType: 0,
    useMobileAcceptType: 0,
    isInternational: false,
    origin: "",
    skillGroupId: [],
  },
};

const mutations = {
  // 更新座席的信息
  updateUserInfo(state, userInfo) {
    state.userInfo = {
      ...state.userInfo,
      ...userInfo,
    };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
