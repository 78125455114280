<template>
  <div class="outerListContainer">
    <el-collapse v-model="activeList" style="width: 230px">
      <el-collapse-item name="queuingList">
        <template #title>
          <div class="queuingListTitle">您所在的技能组排队情况</div>
        </template>
        <QueuingList :queuingList="queuingList"></QueuingList>
      </el-collapse-item>
      <el-collapse-item title="Feedback" name="servingList">
        <template #title>
          <div class="servingListTitle">服务中({{ servingList.length }})</div>
        </template>
        <ServingList :servingList="servingList"></ServingList>
      </el-collapse-item>
      <el-collapse-item title="Efficiency" name="endedList">
        <template #title>
          <div class="endedListTitle">已结束({{ endedList.length }})</div>
        </template>
        <EndedList :endedList="endedList"></EndedList>
      </el-collapse-item>
    </el-collapse>
  </div>
  <ConnectIMUser v-if="canConnectIMUser"></ConnectIMUser>
</template>

<script>
import { computed, defineComponent, reactive, toRefs } from "vue";
import QueuingList from "./QueuingList/index.vue";
import ServingList from "./ServingList/index.vue";
import EndedList from "./EndedList/index.vue";
import ConnectIMUser from "./ConnectIMUser/index.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "OuterConversationList",
  components: {
    QueuingList,
    ServingList,
    EndedList,
    ConnectIMUser,
  },
  props: {},
  setup() {
    const data = reactive({
      activeList: ["queuingList", "servingList", "endedList"],
    });
    const store = useStore();
    const queuingList = computed(() => store.getters["session/queuingList"]);
    const servingList = computed(() => store.getters["session/servingList"]);
    const endedList = computed(() => store.getters["session/endedList"]);
    const canConnectIMUser = computed(
      () => store.state.appSetting?.appSetting?.imContactUserEnable
    );

    return {
      ...toRefs(data),
      queuingList,
      servingList,
      endedList,
      canConnectIMUser,
    };
  },
});
</script>

<style lang="scss" scoped>
.queuingListTitle {
  color: #999;
  margin: 5px 0 5px 20px;
  font-weight: 600;
  font-size: 12px;
}

.servingListTitle {
  color: rgba(21, 29, 50, 0.3);
  font-size: 12px;
  line-height: 24px;
  margin-left: 20px;
}

.endedListTitle {
  color: rgba(21, 29, 50, 0.3);
  font-size: 12px;
  line-height: 24px;
  margin-left: 20px;
}

.outerListContainer {
  overflow-y: scroll;
  height: calc(100vh - 170px);
}

.outerListContainer::-webkit-scrollbar {
  width: 0;
}
</style>

<style lang="scss">
.el-collapse-item__wrap {
  border: 0px !important;
}

.el-collapse-item__header {
  border: 0px !important;
}

.el-collapse-item__content {
  padding: 0px !important;
}
</style>
