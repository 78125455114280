<template>
  <div>
    {{ title }}
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, watchEffect } from "vue";

export default defineComponent({
  props: {
    payload: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const data = reactive({
      title: "",
    });
    watchEffect(() => {
      data.title = props.payload?.content?.header;
    });
    return {
      ...toRefs(data),
    };
  },
});
</script>
<style lang="scss"></style>
