<template>
  <div class="messageProductCard" @click="jumpProductCard">
    <img class="productImg" :src="payload.content.pic" />
    <div class="productCardInformation">
      <div class="productCardTitle">
        {{ payload?.content?.header }}
      </div>
      <div
        style="display: flex; justify-content: space-between; min-width: 150px"
      >
        <div class="productCardDescription">
          {{ payload?.content?.desc }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, watchEffect } from "vue";
export default defineComponent({
  props: {
    payload: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const data = reactive({
      data: {},
      extension: {},
      payload: {},
    });

    watchEffect(() => {
      data.payload = props.payload;
    });

    const jumpProductCard = () => {
      window.open(data.payload?.content?.url, "_blank");
    };

    return {
      ...toRefs(data),
      jumpProductCard,
    };
  },
});
</script>
<style lang="scss" scoped>
.messageProductCard {
  min-width: 224px;
  max-width: 310px;
  background: #ffffff;
  border: 1px solid #dddddd;
  display: flex;
  padding: 12px;
  border-radius: var(--serverBubble--borderRadius, 10px)
    var(--serverBubble--borderRadius, 10px)
    var(--serverBubble--borderRadius, 10px) 0;

  .productImg {
    width: 86px;
    height: 86px;
  }

  .productCardInformation {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .productCardTitle {
      font-size: 12px;
      max-width: 165px;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .productCardDescription {
      font-size: 16px;
      max-width: 165px;
      color: #ff6c2e;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
