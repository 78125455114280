<template>
  <div v-for="item in servingList" :key="item.sessionId">
    <UserItem :userInfo="item" :type="'serving'"></UserItem>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, watchEffect } from "vue";
import UserItem from "../UserItem/index.vue";

export default defineComponent({
  name: "ServingList",
  components: {
    UserItem,
  },
  props: {
    servingList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const data = reactive({
      servingList: [],
    });
    watchEffect(() => {
      data.servingList = props.servingList;
    });
    return {
      ...toRefs(data),
    };
  },
});
</script>
<style lang="scss" scoped></style>
