<template>
  <div class="headerContainer">
    <div>
      <div class="visitorName">
        {{ sessionInfo.showName }}
      </div>
      <div class="visitorInfo" v-if="sessionInfo.sessionType !== 'C2C'">
        <div
          v-if="sessionInfo.sessionStatus === SESSION_STATUS.IN_PROGRESS"
          class="startTime"
        >
          开始时间:{{ sessionInfo.startTime }}
        </div>
        <div class="serveTime">服务时长:{{ serviceDuration }}</div>
        <div class="origin">来源:{{ sessionInfo.origin }}</div>
      </div>
    </div>
    <el-button
      v-if="
        sessionInfo.sessionStatus === SESSION_STATUS.IN_PROGRESS &&
        sessionInfo.sessionType !== 'C2C'
      "
      size="large"
      type="danger"
      round
      plain
      @click="endSession"
      >结束会话</el-button
    >
    <el-button
      v-if="
        sessionInfo.sessionStatus === SESSION_STATUS.FINISHED &&
        sessionInfo.sessionType !== 'C2C'
      "
      size="large"
      type="primary"
      round
      plain
      @click="completeSession"
      >完成服务</el-button
    >
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  watchEffect,
  onMounted,
  onUnmounted,
  inject,
} from "vue";
import { useStore } from "vuex";
import {
  getSessionUserShowSource,
  timestampToDateTime,
  getSessionUserShowName,
} from "../../../utils/index";
import { SESSION_STATUS } from "../../../constants/index";

export default defineComponent({
  name: "ChatHeader",
  props: {
    activeSession: {
      type: Object,
      default: () => null,
    },
  },
  setup(props) {
    const data = reactive({
      tcccSdk: null,
      activeSession: {},
      sessionInfo: {},
      sessionStatus: "",
      serviceDuration: "",
    });
    const store = useStore();
    const tcccsdk = inject("tcccsdk");
    let timer; // 用于存储计时器的 ID
    watchEffect(() => {
      data.tcccSdk = tcccsdk.value;
      data.activeSession = props.activeSession;
      data.sessionInfo = {
        showName: getSessionUserShowName(props.activeSession),
        startTime: timestampToDateTime(
          props.activeSession.timestamp * 1000,
          "full"
        ),
        origin: getSessionUserShowSource(props.activeSession),
        sessionStatus: props.activeSession.status,
        sessionType: props.activeSession.sessionId?.startsWith("C2C")
          ? "C2C"
          : "GROUP",
      };
    });

    const endSession = async () => {
      store.commit("session/setCurrentSession", undefined);
      store.commit("session/endOuterSessionList", data.activeSession);
      await data.tcccSdk.Chat.endChat({
        sessionId: data.activeSession.sessionId,
      });
    };
    const completeSession = async () => {
      store.commit("session/setCurrentSession", undefined);
      store.commit("session/completeOuterSessionList", data.activeSession);
      await data.tcccSdk.Chat.completeChat({
        sessionId: data.activeSession.sessionId,
      });
    };

    const calculateDuration = () => {
      const now = Date.now();
      const diffInSeconds = Math.floor(
        (now - props.activeSession.timestamp * 1000) / 1000
      );
      const hours = Math.floor(diffInSeconds / 3600);
      const minutes = Math.floor((diffInSeconds % 3600) / 60);
      const seconds = diffInSeconds % 60;
      data.serviceDuration = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    };
    onMounted(() => {
      calculateDuration(); // 初始化服务时长
      timer = window.setInterval(calculateDuration, 1000); // 每秒更新服务时长
    });
    onUnmounted(() => {
      data.serviceDuration = "";
      if (timer !== undefined) {
        window.clearInterval(timer);
      }
    });
    return {
      ...toRefs(data),
      endSession,
      completeSession,
      SESSION_STATUS,
    };
  },
});
</script>

<style lang="scss" scoped>
.headerContainer {
  display: flex;
  padding: 14px 20px;
  max-width: 100%;
  border-bottom: 1px solid #e5e5e5;
  justify-content: space-between;
  background-color: white;

  .visitorName {
    color: #333333;
    height: 20px;
    display: block;
    overflow: hidden;
    font-size: 14px;
    word-break: break-all;
    align-items: center;
    font-family: PingFangSC-Medium !important;
    text-overflow: ellipsis;
  }

  .visitorInfo {
    display: flex;
    margin-top: 5px;

    .startTime {
      color: #999;
      overflow: hidden;
      font-size: 12px;
      margin-top: 3px;
      align-items: center;
      font-family: PingFangSC-Regular !important;
      white-space: nowrap;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      margin-right: 10px;
    }

    .serveTime {
      color: #999;
      overflow: hidden;
      font-size: 12px;
      margin-top: 3px;
      align-items: center;
      font-family: PingFangSC-Regular !important;
      white-space: nowrap;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
    }

    .origin {
      color: #999;
      overflow: hidden;
      font-size: 12px;
      margin-top: 3px;
      align-items: center;
      font-family: PingFangSC-Regular !important;
      white-space: nowrap;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      margin-left: 10px;
    }
  }
}
</style>
