import { createStore } from "vuex";
import appSetting from "./appSetting";
import message from "./message";
import session from "./session";
import userInfo from "./userInfo";
import agent from "./agent";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appSetting,
    message,
    session,
    userInfo,
    agent,
  },
});
