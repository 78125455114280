<template>
  <div class="message-file">
    <div class="box" @click="download">
      <div class="icon" :class="[tailName()]"></div>
      <div class="message-file-content">
        <div :id="`fileName-${data.message.ID}`" class="fileName">
          {{ data.name }}
        </div>
      </div>
    </div>
    <progress
      :style="{ marginTop: '5px' }"
      v-if="data.progress"
      :value="data.progress"
      max="1"
    ></progress>
  </div>
</template>

<script>
import { defineComponent, watchEffect, reactive, toRefs } from "vue";

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const data = reactive({
      data: {},
      overflowFlag: false,
      fileTail: {
        doc: "word",
        docx: "word",
        ppt: "ppt",
        pptx: "ppt",
        xls: "excel",
        xlsx: "excel",
        pdf: "pdf",
        zip: "zip",
        rar: "zip",
        "7z": "zip",
        tar: "zip",
        gz: "zip",
        xz: "zip",
        bz2: "zip",
        txt: "txt",
        svg: "image",
        jpg: "image",
        bmp: "image",
        gif: "image",
        png: "image",
        jpeg: "image",
        tif: "image",
        wmf: "image",
        dib: "image",
      },
      tail: "",
    });

    watchEffect(() => {
      try {
        data.data = props.data;
        const getFileName = (fileName) => {
          let showFileName = "";
          let disLength = fileName.length;
          let substringC = 12;
          let nameLength = 0;
          let cutIndex = 0;
          for (let i = 0; i < disLength; i++) {
            if (escape(fileName[i]).indexOf("%u") < 0) {
              //不是中文
              nameLength += 1;
            } else {
              //中文
              nameLength += 2;
            }
            if (nameLength > 12) {
              cutIndex = i;
              break;
            }
          }
          if (nameLength > substringC) {
            var reg = new RegExp(
              "^(^.{" + cutIndex + "})(.+)(.{2}\\.+\\w+$)$",
              "g"
            );
            showFileName = fileName.replace(reg, "$1...$3");
          } else {
            showFileName = fileName;
          }
          return showFileName;
        };

        data.data.name = getFileName(data.data.name);
      } catch (e) {
        console.log(e);
      }
    });

    const tailName = () => {
      const tailArray = data.data.name.split(".");
      const name = tailArray[tailArray.length - 1];
      data.tail = name;
      return "icon-files-" + (data.fileTail[name.toLowerCase()] ?? "unknown");
    };

    const download = () => {
      const file = data.data;
      const option = {
        mode: "cors",
        headers: new Headers({
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      };
      // 浏览器支持fetch则用blob下载，避免浏览器点击a标签，跳转到新页面预览的行为
      // If the browser supports fetch, use blob to download, so as to avoid the browser clicking the a tag and jumping to the preview of the new page
      if (window?.fetch) {
        fetch(file.url, option)
          .then((res) => res.blob())
          .then((blob) => {
            const a = document.createElement("a");
            const url = window.URL.createObjectURL(blob);
            a.href = url + `#?filename=${file.name}`;
            a.download = file.name;
            a.click();
          });
      } else {
        const a = document.createElement("a");
        a.href = file.url + `#?filename=${file.name}`;
        a.target = "_blank";
        a.download = file.name;
        a.click();
      }
    };

    return {
      ...toRefs(data),
      download,
      tailName,
    };
  },
});
</script>

<style lang="scss" scoped>
.message-file {
  display: flex;
  flex-direction: column;

  .box {
    flex: 1;
    display: flex;
    cursor: pointer;
    align-items: center;

    .message-file-content {
      overflow: hidden;
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  progress {
    width: 100%;
    color: #006eff;
    appearance: none;
    border-radius: 0.25rem;
    background: rgba(#ffffff, 1);
    width: 100%;
    height: 0.5rem;

    &::-webkit-progress-value {
      background-color: #006eff;
      border-radius: 0.25rem;
    }

    &::-webkit-progress-bar {
      border-radius: 0.25rem;
      background: rgba(#ffffff, 1);
    }

    &::-moz-progress-bar {
      color: #006eff;
      background: #006eff;
      border-radius: 0.25rem;
    }
  }

  .fileName {
    width: 160px;
  }

  .fileSizeStatus {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }

  .icon-files-word {
    background-size: 100% 100% !important;
    width: 40px;
    height: 40px;
    margin: 0px 10px 0px 0px;
    background-size: contain;
    background: url("../../../../assets/images/word.png") no-repeat;
  }

  .icon-files-excel {
    background-size: 100% 100% !important;
    width: 40px;
    height: 40px;
    margin: 0px 10px 0px 0px;
    background-size: contain;
    background: url("../../../../assets/images/excel.png") no-repeat;
  }

  .icon-files-image {
    background-size: 100% 100% !important;
    width: 40px;
    height: 40px;
    margin: 0px 10px 0px 0px;
    background-size: contain;
    background: url("../../../../assets/images/image.png") no-repeat;
  }

  .icon-files-pdf {
    background-size: 100% 100% !important;
    width: 40px;
    height: 40px;
    margin: 0px 10px 0px 0px;
    background-size: contain;
    background: url("../../../../assets/images/pdf.png") no-repeat;
  }

  .icon-files-ppt {
    background-size: 100% 100% !important;
    width: 40px;
    height: 40px;
    margin: 0px 10px 0px 0px;
    background-size: contain;
    background: url("../../../../assets/images/ppt.png") no-repeat;
  }

  .icon-files-txt {
    background-size: 100% 100% !important;
    width: 40px;
    height: 40px;
    margin: 0px 10px 0px 0px;
    background-size: contain;
    background: url("../../../../assets/images/txt.png") no-repeat;
  }

  .icon-files-unknown {
    background-size: 100% 100% !important;
    width: 40px;
    height: 40px;
    margin: 0px 10px 0px 0px;
    background: url("../../../../assets/images/unknown.png") no-repeat;
  }

  .icon-files-zip {
    background-size: 100% 100% !important;
    width: 40px;
    height: 40px;
    margin: 0px 10px 0px 0px;
    background-size: contain;
    background: url("../../../../assets/images/zip.png") no-repeat;
  }
}
</style>
