<template>
  <div class="actionsList">
    <Emoji :activeSession="activeSession" @clickEmoji="clickEmoji"></Emoji>
    <Image :activeSession="activeSession"></Image>
    <Video :activeSession="activeSession"></Video>
    <File :activeSession="activeSession"></File>
    <Rate
      v-if="!activeSession.sessionId?.startsWith('C2C')"
      :activeSession="activeSession"
    ></Rate>
    <Transfer
      v-if="!activeSession.sessionId?.startsWith('C2C')"
      @clickTransfer="clickTransfer"
    ></Transfer>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, watchEffect } from "vue";
import File from "./inputActions/file.vue";
import Emoji from "./inputActions/emoji.vue";
import Image from "./inputActions/image.vue";
import Video from "./inputActions/video.vue";
import Rate from "./inputActions/rate.vue";
import Transfer from "./inputActions/transfer.vue";

export default defineComponent({
  name: "ChatInputActions",
  props: {
    activeSession: {
      type: Object,
      default: () => null,
    },
  },
  components: {
    File,
    Emoji,
    Image,
    Video,
    Rate,
    Transfer,
  },
  setup(props, ctx) {
    const data = reactive({
      activeSession: {},
    });
    watchEffect(() => {
      data.activeSession = props.activeSession;
    });
    const clickEmoji = (options) => {
      ctx.emit("clickEmoji", options);
    };
    const clickTransfer = () => {
      ctx.emit("clickTransfer");
    };
    return {
      ...toRefs(data),
      clickEmoji,
      clickTransfer,
    };
  },
});
</script>

<style lang="scss" scoped>
.actionsList {
  display: flex;
}
</style>
