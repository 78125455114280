<template>
  <div>{{ payload.content.header || payload.content.title }}</div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "messageBranch",
  props: {
    payload: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
});
</script>
<style lang="scss" scoped></style>
