<template>
  <div
    class="message-audio"
    :class="[data.message.flow === 'out' && 'reserve']"
    @click.stop="play"
    :style="`width: ${data?.second * 10 + 40}px`"
  >
    <i
      class="icon icon-voice"
      :class="[data.message.flow === 'out' && 'icon-reserve']"
    ></i>
    <label>{{ data.second }}s</label>
    <audio ref="audio" :src="data.url"></audio>
  </div>
</template>

<script>
import { defineComponent, watch, reactive, toRefs, ref } from "vue";

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const data = reactive({
      data: {},
      show: false,
    });

    const audio = ref(null);

    watch(
      () => props.data,
      () => {
        data.data = props.data;
      },
      { deep: true, immediate: true }
    );

    const play = () => {
      try {
        const audio = new Audio(data.data?.url);
        if (!audio.paused) {
          audio.pause();
          audio.load();
        }
        if (audio.paused) {
          audio.play();
          data.show = true;
        } else {
          audio.pause();
          audio.load();
          data.show = false;
        }
      } catch (e) {
        console.log(e);
      }
    };

    return {
      ...toRefs(data),
      audio,
      play,
    };
  },
});
</script>
<style lang="scss" scoped>
.message-audio {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  max-width: 100%;
  overflow: hidden;

  .icon {
    margin: 0 4px;
  }

  .icon-voice {
    width: 16px;
    height: 16px;
    background: url("../../../../assets/icon/voice.png") no-repeat;
    background-size: contain;
  }

  audio {
    width: 0;
    height: 0;
  }
}

.reserve {
  flex-direction: row-reverse;
}
</style>
