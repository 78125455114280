<template>
  <div
    class="transferItem"
    :class="[selected ? 'selected' : '']"
    @click="selectTransferItem"
  >
    <div class="dot"></div>
    <div style="width: 100%; word-break: break-all">{{ showName }}</div>
    <div
      style="width: 100%; color: rgb(28, 201, 83); font-weight: 800"
      v-if="freeStaffCount != 0"
    >
      空闲({{ freeStaffCount }})
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, watchEffect } from "vue";

export default defineComponent({
  name: "ConversationList",
  props: {
    showName: {
      type: String,
      default: () => "",
    },
    type: {
      type: String,
      default: () => "",
    },
    freeStaffCount: {
      type: Number,
      default: () => 0,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    selected: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, ctx) {
    const data = reactive({
      showName: "",
      type: "",
      freeStaffCount: 0,
      item: {},
      selected: false,
    });
    watchEffect(() => {
      data.showName = props.showName;
      data.freeStaffCount = props.freeStaffCount;
      data.type = props.type;
      data.item = props.item;
      data.selected = props.selected;
    });
    const selectTransferItem = () => {
      ctx.emit("selectTransferItem", { item: data.item, type: data.type });
    };
    return {
      ...toRefs(data),
      selectTransferItem,
    };
  },
});
</script>
<style lang="scss" scoped>
.transferItem {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  color: #333;
  width: 120px;
  cursor: pointer;
  height: 80px;
  margin: 0 4px 10px 4px;
  overflow: hidden;
  font-size: 12px;
  background: #f2f2f2;
  text-align: center;
  padding-top: 2px;
  border-radius: 4px;
}

.dot {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: rgb(28, 201, 83);
  font-size: 12px;
  color: white;
  line-height: 20px;
  text-align: center;
}

.selected {
  color: #006eff;
  border: 1px solid #006eff;
  background: rgba(0, 110, 255, 0.1);
  font-family: PingFangSC-Medium !important;
}
</style>
